import { APPRAISAL_FILE_STATUS, BUSINESS_ADVANTAGE_OPTIONS } from "constant/common";
import { ROLE_STATUS } from "constant/common";

const randomId = function (length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

const renderAppraisalStatus = (
  status: number | string,
  isReceivedLos?: boolean | null,
  isSendToLos?: boolean | null
) => {
  if (status === 1 && isReceivedLos)
    return APPRAISAL_FILE_STATUS.ONE_PLUS_LABEL;
  if (status === APPRAISAL_FILE_STATUS.TWENTY && isSendToLos)
    return APPRAISAL_FILE_STATUS.TWENTY_ONE_LABEL;
  switch (status) {
    case APPRAISAL_FILE_STATUS.MINUS_ONE:
      return APPRAISAL_FILE_STATUS.MINUS_ONE_LABEL;
    case APPRAISAL_FILE_STATUS.ONE:
      return APPRAISAL_FILE_STATUS.ONE_LABEL;
    case APPRAISAL_FILE_STATUS.ZERO:
      return APPRAISAL_FILE_STATUS.ZERO_LABEL;
    case APPRAISAL_FILE_STATUS.TWO:
      return APPRAISAL_FILE_STATUS.TWO_LABEL;
    case APPRAISAL_FILE_STATUS.THREE:
      return APPRAISAL_FILE_STATUS.THREE_LABEL;
    case APPRAISAL_FILE_STATUS.SIX:
      return APPRAISAL_FILE_STATUS.SIX_LABEL;
    case APPRAISAL_FILE_STATUS.SEVEN:
      return APPRAISAL_FILE_STATUS.SEVEN_LABEL;
    case APPRAISAL_FILE_STATUS.EIGHT:
      return APPRAISAL_FILE_STATUS.EIGHT_LABEL;
    case APPRAISAL_FILE_STATUS.NINE:
      return APPRAISAL_FILE_STATUS.NINE_LABEL;
    case APPRAISAL_FILE_STATUS.TEN:
      return APPRAISAL_FILE_STATUS.TEN_LABEL;
    case APPRAISAL_FILE_STATUS.ELEVEN:
      return APPRAISAL_FILE_STATUS.ELEVEN_LABEL;
    case APPRAISAL_FILE_STATUS.TWELVE:
      return APPRAISAL_FILE_STATUS.TWELVE_LABEL;
    case APPRAISAL_FILE_STATUS.THIRTEEN:
      return APPRAISAL_FILE_STATUS.THIRTEEN_LABEL;
    case APPRAISAL_FILE_STATUS.FOURTEEN:
      return APPRAISAL_FILE_STATUS.FOURTEEN_LABEL;
    case APPRAISAL_FILE_STATUS.FIFTEEN:
      return APPRAISAL_FILE_STATUS.FIFTEEN_LABEL;
    case APPRAISAL_FILE_STATUS.SIXTEEN:
      return APPRAISAL_FILE_STATUS.SIXTEEN_LABEL;
    case APPRAISAL_FILE_STATUS.SEVENTEEN:
      return APPRAISAL_FILE_STATUS.SEVENTEEN_LABEL;
    case APPRAISAL_FILE_STATUS.NINETEEN:
      return APPRAISAL_FILE_STATUS.NINETEEN_LABEL;
    case APPRAISAL_FILE_STATUS.TWENTY:
      return APPRAISAL_FILE_STATUS.TWENTY_LABEL;

    default:
      return "";
  }
};

const renderRoleStatus = (status: number | string) => {
  switch (status) {
    case ROLE_STATUS.ZERO:
      return ROLE_STATUS.ZERO_LABEL;
    case ROLE_STATUS.ONE:
      return ROLE_STATUS.ONE_LABEL;
    default:
      return "";
  }
};
const renderBusinessAdvantageLabel = (id: string | number) => {
  const businessAdvantageIndex =
  BUSINESS_ADVANTAGE_OPTIONS.findIndex(
    //compare number vs string should ==
    (x) => x.value === id
  ); 
  return businessAdvantageIndex !== -1
    ? BUSINESS_ADVANTAGE_OPTIONS[businessAdvantageIndex].label
    : id;
};
const removeDoubleSpaces = (str: string, trimLinesToo: boolean = false) => {
  let strResult = ``;
  let prev: any = ``;
  const strCopy = [...str];

  while (strCopy.length) {
    const chr = strCopy.shift();
    strResult += prev + chr !== `  ` ? chr : ``;
    prev = chr;
  }

  return trimLinesToo
    ? strResult
        .split(/\n/)
        .map((v) => v.trim())
        .join(`\n`)
    : strResult.trim();
};

export {
  randomId,
  renderAppraisalStatus,
  renderRoleStatus,
  removeDoubleSpaces,
  renderBusinessAdvantageLabel,
};
