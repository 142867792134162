import { request, SBAAxiosClient } from "./base";
import { LoginData } from "../constants/types/auth.type";
import axios from "axios";
import { LOCAL_STORAGE_KEY } from "constant/enums";

const authApi = {
  login: (data: LoginData) => {
    return SBAAxiosClient(`/authentication/api/v1/login`, {
      method: "POST",
      data,
    });
  },
  checkToken: () => {
    return SBAAxiosClient("/auth/check-token", {
      method: "GET",
    });
  },
  refesh: async () => {
    return await SBAAxiosClient(
      `${process.env.REACT_APP_SERVER_URL}/authentication/api/v1/refreshtoken`,
      {
        method: "GET",
      }
    );
  },
  loginWithMsal: async (token: string) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/authentication/api/v1/loginToken`,
      token,
    );
  },
  logout: () => {
    return SBAAxiosClient("/auth/logout", {
      method: "GET",
    });
  },
};

export default authApi;
