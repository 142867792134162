export const APPRAISAL_FILE_STATUS = {
  LOS_RECEIVED: "LOS_RECEIVED",
  MINUS_ONE: -1,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  NINETEEN: 19,
  TWENTY:20,
  TWENTY_ONE:21,
  MINUS_ONE_LABEL: "Từ chối",
  ONE_LABEL: "Tiếp nhận từ LOS",
  ONE_PLUS_LABEL: "Hoàn thành tiếp nhận",
  ZERO_LABEL: "Tạo hồ sơ thủ công",
  TWO_LABEL: "Chờ bổ sung HSPL",
  THREE_LABEL: "Đã bổ sung HSPL",
  SIX_LABEL: "Chờ phân công",
  SEVEN_LABEL: "Chưa có lịch khảo sát",
  EIGHT_LABEL: "Đã có lịch khảo sát",
  NINE_LABEL: "Chờ bổ sung HSPL",
  TEN_LABEL: "Đã bổ sung HSPL",
  ELEVEN_LABEL: "Đang khảo sát",
  TWELVE_LABEL: "Hoàn thành BBKS",
  THIRTEEN_LABEL: "Lập tờ trình",
  FOURTEEN_LABEL: "Chờ duyệt",
  FIFTEEN_LABEL: "Đang duyệt",
  SIXTEEN_LABEL: "Đã duyệt",
  SEVENTEEN_LABEL: "Đã từ chối duyệt và chờ điều chỉnh",
  NINETEEN_LABEL: "Đã ký số",
  TWENTY_LABEL: "Đã gửi thông báo kết quả TĐ",
  TWENTY_ONE_LABEL: "Đã gửi kết quả TĐ",
};
export const ROLE_STATUS = {
  ZERO: 0,
  ONE: 1,
  ZERO_LABEL: "Ngừng hoạt động",
  ONE_LABEL: "Đang hoạt động",
};
export const APPROVAL_STATUS_LABEL: { [key: number]: string } = {
  [-1]: "Chưa gửi duyệt",
  0: "Chờ phê duyệt",
  1: "Đang duyệt",
  2: "Đã từ chối, Chờ điều chỉnh",
  3: "Đã phê duyệt",
};
export const APPROVAL_STATUS = {
  NOT_APPROVAL: -1,
  NOT_APPROVED: 0,
  APPROVING: 1,
  REJECTED: 2,
  APPROVED: 3,
};
export const APPRAISAL_IMAGE_UPLOAD_TYPE = {
  // TAB hình ảnh
  // Upload hình ảnh
  NORMAL_IMAGE: 2,
  // Ảnh sơ đồ
  DIAGRAM_IMAGE: 5,
  // Hình ảnh phụ lục tài sản
  APPENDIX_IMAGE: 1,

  // TAB định vị
  // bản đồ định vị
  LOCATION_IMAGE: 3,
  // Quy hoạch
  PLAN_IMAGE: 4,

  // bản đồ thể hiện tstd và tsss
  MAP_TSTD_AND_TSSS: 7,
};
export const ASSET_PRICES_SHARED_TYPE = {
  // nhà đất
  PLAN_USING: 0,
  // Chung cư
  APARTMENT: 1,
  // ptvt đường bộ
  ROAD_VEHICLE: 2,
  // ptvt đường thuỷ
  WATERWAY_VEHICLE: 3,
  // máy móc thiết bị
  DEVICE: 4,
  // Dự án
  PROJECT: 5,
  // Dự toán
  ESTIMATE: 6,
};
export const listKhiaCanh = [
  "Tài chính",
  "Khách hàng",
  "Quy định nội bộ",
  "Học hỏi-phát triển",
];
export const listTypeDoc = [
  "Hồ sơ ngoại thành",
  "Hồ sơ tỉnh",
  "Hồ sơ dự án (Tài sản giá trị dưới 500 tỷ)",
  "Hồ sơ dự án (Tài sản giá trị từ 500 tỷ đến 1000 tỷ)",
  "Hồ sơ dự án (Tài sản giá trị trên 1000 tỷ)",
  "Hồ sơ dự toán (Tài sản giá trị dưới 100 tỷ)",
  "Hồ sơ dự toán (Tài sản giá trị từ 100 tỷ trở lên)",
  "Hồ sơ cây trồng, dây chuyền MMTB, PTĐT (Tài sản giá trị dưới 50 tỷ)",
  "Hồ sơ cây trồng, dây chuyền MMTB, PTĐT (Tài sản giá trị trên 50 tỷ dưới 100 tỷ)",
  "Hồ sơ cây trồng, dây chuyền MMTB, PTĐT (Tài sản giá trị từ 100 tỷ trở lên)",
];

export const LIQUIDITY_ADVANTAGE_OPTIONS = [
  { value: "1", label: "Tốt" },
  { value: "2", label: "Trung bình" },
  { value: "3", label: "Kém" },
];

export const APPROVAL_TAB_OPTIONS = [
  { value: "1", label: "Thông tin tờ trình" },
  { value: "2", label: "Thông tin biên bản họp ban" },
];

//PTĐB
export const ROAD_ADVANTAGE_OPTIONS = [
  { value: 21, label: "Xe ô tô con" },
  { value: 22, label: "Xe ô tô khách" },
  { value: 23, label: "Xe ô tô tải" },
  { value: 24, label: "Xe ô tô đầu kéo" },
  { value: 25, label: "Xe ô tô rơ-mooc, sơ mi rơ-mooc" },
  { value: 26, label: "Xe chuyên dùng" },
  { value: 27, label: "Xe 02 bánh" },
];

export const ROAD_FUEL_ADVANTAGE_OPTIONS = [
  { value: 18, label: "Xăng" },
  { value: 19, label: "Dầu" },
  { value: 20, label: "Điện" },
  { value: 21, label: "Hybrid (Xăng-Điện)" },
  { value: 22, label: "Khí CNG" },
  { value: 23, label: "Khác" },
];

export const ROAD_GEARBOX_ADVANTAGE_OPTIONS = [
  { value: 8, label: "Hộp số sàn MT (Manual Transmission)" },
  { value: 9, label: "Hộp số tự động AT (Automatic Transmission)" },
  {
    value: 10,
    label: "Hộp số tự động vô cấp CVT (Continuous Variable Transmission)",
  },
  { value: 11, label: "Hộp số ly hợp kép DCT (Dual Clutch Transmission)" },
  {
    value: 12,
    label: "Hộp số bán tự động MAT (Manual-Automatic Transmission)",
  },
  { value: 13, label: "Hộp số sang số trực tiếp DSG (Direct Shift Gearbox)" },
  {
    value: 14,
    label: "Hộp số tùy chọn số sàn hoặc tự động TT (Tiptronic Transmission)",
  },
];

export const ROAD_WHEELFORMULAS_ADVANTAGE_OPTIONS = [
  { value: 7, label: "Xe có một cầu chủ động 4×2" },
  { value: 8, label: "Xe có hai cầu chủ động 4×4" },
  { value: 9, label: "Xe có hai cầu chủ động, một cầu bị động 6×4" },
  { value: 10, label: "Xe có ba cầu chủ động 6×6" },
  { value: 11, label: "Xe có bốn cầu chủ động 8×8" },
  { value: 12, label: "Khác" },
];

export const BUSINESS_ADVANTAGE_OPTIONS = [
  { value: 1, label: "Tốt" },
  { value: 2, label: "Trung bình" },
  { value: 3, label: "Kém" },
];

export const DISPUTE_INFORMATION_OPTIONS = [
  {
    value: "1",
    label: "Có",
  },
  { value: "0", label: "Không" },
];
export const APPRAISAL_STATUS_OPTIONS = [
  {
    label: APPRAISAL_FILE_STATUS.MINUS_ONE_LABEL,
    value: APPRAISAL_FILE_STATUS.MINUS_ONE.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.ONE_LABEL,
    value: APPRAISAL_FILE_STATUS.ONE.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.ONE_PLUS_LABEL,
    value: APPRAISAL_FILE_STATUS.LOS_RECEIVED.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.ZERO_LABEL,
    value: APPRAISAL_FILE_STATUS.ZERO.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.TWO_LABEL,
    value: APPRAISAL_FILE_STATUS.TWO.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.THREE_LABEL,
    value: APPRAISAL_FILE_STATUS.THREE.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.SIX_LABEL,
    value: APPRAISAL_FILE_STATUS.SIX.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.SEVEN_LABEL,
    value: APPRAISAL_FILE_STATUS.SEVEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.EIGHT_LABEL,
    value: APPRAISAL_FILE_STATUS.EIGHT.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.NINE_LABEL,
    value: APPRAISAL_FILE_STATUS.NINE.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.TEN_LABEL,
    value: APPRAISAL_FILE_STATUS.TEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.ELEVEN_LABEL,
    value: APPRAISAL_FILE_STATUS.ELEVEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.TWELVE_LABEL,
    value: APPRAISAL_FILE_STATUS.TWELVE.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.THIRTEEN_LABEL,
    value: APPRAISAL_FILE_STATUS.THIRTEEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.FOURTEEN_LABEL,
    value: APPRAISAL_FILE_STATUS.FOURTEEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.FIFTEEN_LABEL,
    value: APPRAISAL_FILE_STATUS.FIFTEEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.SIXTEEN_LABEL,
    value: APPRAISAL_FILE_STATUS.SIXTEEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.SEVENTEEN_LABEL,
    value: APPRAISAL_FILE_STATUS.SEVENTEEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.NINETEEN_LABEL,
    value: APPRAISAL_FILE_STATUS.NINETEEN.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.TWENTY_LABEL,
    value: APPRAISAL_FILE_STATUS.TWENTY.toString(),
  },
  {
    label: APPRAISAL_FILE_STATUS.TWENTY_ONE_LABEL,
    value: APPRAISAL_FILE_STATUS.TWENTY_ONE.toString(),
  },
];

export const axisShapeLandLocation: any = {
  "01": "105",
  "02": "105.5",
  "04": "105.75",
  "06": "106.5",
  "08": "106",
  "10": "104.75",
  "11": "103",
  "12": "103",
  "14": "104",
  "15": "104.75",
  "17": "106",
  "19": "106.5",
  "20": "107.25",
  "22": "107.75",
  "24": "107",
  "25": "104.75",
  "26": "105",
  "27": "105.5",
  "30": "105.5",
  "31": "105.75",
  "33": "105.5",
  "34": "105.5",
  "35": "105",
  "36": "105.5",
  "37": "105",
  "38": "105",
  "40": "104.75",
  "42": "105.5",
  "44": "106",
  "45": "106.25",
  "46": "107",
  "48": "107.75",
  "49": "107.75",
  "51": "108",
  "52": "108.25",
  "54": "108.5",
  "56": "108.25",
  "58": "108.25",
  "60": "108.5",
  "62": "107.5",
  "64": "108.5",
  "66": "108.5",
  "67": "108.5",
  "68": "107.75",
  "70": "106.25",
  "72": "105.5",
  "74": "105.75",
  "75": "107.75",
  "77": "107.75",
  "79": "105.75",
  "82": "105.75",
  "83": "105.75",
  "84": "105.5",
  "86": "105.5",
  "87": "105",
  "88": "105.75",
  "89": "104.75",
  "91": "104.5",
  "92": "105",
  "93": "105",
  "94": "105.5",
  "95": "105",
  "96": "104.5",
};

export const ROLES = {
  CVTD: "ROLE_CVTD",
  CBTH: "ROLE_CBTH",
  CBTM: "ROLE_PPTĐG",
  CVC: "ROLE_CVC",
  CVCC: "ROLE_CVCC",
  TPTĐG: "ROLE_TPTĐG",
  TGĐ: "ROLE_TGĐ",
  PTGĐ: "ROLE_PTGĐ",
  GĐCN: "ROLE_GĐCN",
  TPCNHN: "ROLE_TPCNHN",
  PGĐCN: "ROLE_PGĐCN",
  CT: "ROLE_CT",
  TBP: "ROLE_TBP",
  ADMIN: "ROLE_ADMIN",
};
export const VIETNAM_ID = 232;