import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface appendixMethodsState {
  unitPrice: number | null;
}

const initialState: appendixMethodsState = {
  unitPrice: null,
};

export const appendixMethodsSlice = createSlice({
  name: "appendixMethods",
  initialState,
  reducers: {
    setUnitPrice: (state, action: PayloadAction<number | null>) => {
      state.unitPrice = action.payload;
    },
  },
});

export const { setUnitPrice } = appendixMethodsSlice.actions;

export default appendixMethodsSlice.reducer;
