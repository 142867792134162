import { FilterDebtComparison } from "constant/types";
import useSWR from "swr";
import { serialize } from "utils/validate";

export const useReportDebtComparison = (
  filters?: FilterDebtComparison
) => {
  const { data, error, isLoading, mutate } = useSWR(
    `/bussiness/api/v1/statistic/debt/comparison/monthly?${serialize({
      ...filters,
    })}`,
    { refreshInterval: 0 }
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};
