import useSWR from "swr";

export const useLegalStatus = () => {
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(`/bussiness/api/v1/legalStatus`, { refreshInterval: 0 });

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};
