import { LOCAL_STORAGE_KEY } from "constant/enums";

const getUsername = (): string => {
  const username = localStorage.getItem(LOCAL_STORAGE_KEY.USERNAME);
  return username ? username : "";
};

const getDistrictOptions = (): string => {
  const options = localStorage.getItem(LOCAL_STORAGE_KEY.DISTRICT_OPTIONS);
  return options ? options : "";
};

const getWardOptions = (): string => {
  const options = localStorage.getItem(LOCAL_STORAGE_KEY.WARD_OPTIONS);
  return options ? options : "";
};

export {
  getUsername,
  getDistrictOptions,
  getWardOptions,
};
